export default {
  name: "",
  data() {
    return {
      breadcrumbData: [{
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/agentVerificationActivities",
          name: "agentVerificationActivities",
          title: "代理商市场活动"
        },
        {
          path: "/agentVerificationActivities/activityView",
          name: "activityView",
          title: "活动查看"
        }
      ],
      pageLoadFlag: false,
      detilInfo:{
      },
      columns: [
        {
            
          title: "种类",
          dataIndex: "type",
          width: 400,
        },
        {
          title: "数量",
          dataIndex: "num",
          width: 400,
        },
        {
          title: "单价",
          dataIndex: "price",
          width: 400,
        },
        {
          title: "小计",
          dataIndex: "total",
          width: 400,
        },
        {
          title: "备注",
          dataIndex: "message",
          width: 400,
        },
       
       
      ],
      tableData: [
          {
              type:'zhonlei',
              num:'num',
              price:'0',
              total:'0',
              message:'beuzhu'
          }
      ],
    };
  },


  mounted() {
  
  },
  
  methods:{

  }
};